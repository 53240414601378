import BaseAction from '@/actions/base_action.js'

export default class extends BaseAction {
  execute(spec, component) {
    const cardId = spec.cardId
    const memberId = spec.memberId
    let promise = null
    if (spec.assigned) {
      promise = $tpu.r.cardMembers.create(cardId, memberId)
    } else {
      promise = $tpu.r.cardMembers.destroy(cardId, memberId)
    }

    this.executeCardUpdate(promise, spec, component, true)
  }
}
